import { gql } from "graphql-request";
import { useMemo } from "react";
import useSWR from "swr/immutable";

import type { CurrentUserQuery, UserType } from "@/lib/graphql";

import type { Config } from "./useConfigContext";

const userQuery = gql`
  query currentUser {
    currentUser {
      username
      email
      firstName
      lastName
      displayName
      configs
    }
  }
`;

export const logout = () => {
  window.location.href = "/backend/oidc/logout/";
};

type AuthUser = {
  configs: Config[];
} & UserType;

export default function useAuthUser() {
  const { data } = useSWR<CurrentUserQuery>(userQuery);

  const user = useMemo<AuthUser | null>(() => {
    if (data?.currentUser?.username) {
      return data.currentUser as AuthUser;
    }
    return null;
  }, [data?.currentUser]);

  return user;
}
