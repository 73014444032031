export const PERSIST_KEY = "moco3.notification-filter";

/* Focus style for input or container of inputs (like multiselect) */
// export const FOCUS_WITHIN_CLASS_NAME = `outline-hidden focus-within:rounded-sm focus-within:border-sky-300 focus-within:ring-4 focus-within:ring-sky-500/20`;
export const FOCUS_WITHIN_CLASS_NAME = `focus-within:rounded-sm focus-within:outline-3 focus-within:outline-sky-500/20`;

export const FOCUS_CLASS_NAME = `focus:outline-3 focus:outline-sky-500/20 focus:rounded-sm`;
export const FOCUS_VISIBLE_CLASS_NAME = `focus-visible:outline-3 focus-visible:outline-sky-500/20`;

export const SECTION_CLASS_NAME =
  "border-athensgray-100 relative space-y-4 rounded-md border bg-white p-4";

export const SCROLLBAR_CLASS_NAME =
  "[&::-webkit-scrollbar]:w-[13px] [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-[#EAECF0] [&::-webkit-scrollbar-thumb]:border-3 [&::-webkit-scrollbar-thumb]:border-white [&::-webkit-scrollbar-thumb]:bg-clip-padding";

// TODO: StopFinder and MultiSelect should use the same combo box component maye.
export const LIST_RESULTS_CLASS_NAME = `w-full absolute z-10 mt-2 max-h-[255px] overflow-auto rounded-md border border-[#D0D5DD] bg-white shadow-sm ${SCROLLBAR_CLASS_NAME}`;
