import { useContext, useEffect } from "react";
import { createContext } from "react";

import useAuthUser from "./useAuthUser";

import type { Dispatch, SetStateAction } from "react";

export type SettingName =
  | "extent_schematic"
  | "extent_topographic"
  | "graph_schematic"
  | "graph_topographic"
  | "moco-default_affected_product_names"
  | "moco-preview_url_dev" // does it exist?
  | "moco-preview_url_stag" // does it exist?
  | "moco-preview_url"
  | "moco-textual_content_sizes"
  | "routing_mot"
  | "slug"
  | "stops_api_bbox"
  | "stops_api_prefagencies"
  | "stops_mots"
  | "style_schematic"
  | "style_topographic"
  | "styles";

export interface Config {
  [key: string]: Record<string, string> | string;
  modified_at: string;
  settings: Record<SettingName, string>;
  slug: string;
  title: string;
}

export interface ConfigContext {
  config?: Config | undefined;
  setConfig: Dispatch<SetStateAction<Config | undefined>>;
}

export const ConfigContext = createContext<ConfigContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConfig: () => {},
} as ConfigContext);

const configSlug = global.localStorage?.getItem("moco.config");

export default function useConfigContext() {
  const context = useContext<ConfigContext>(ConfigContext);
  const user = useAuthUser();

  useEffect(() => {
    // Initialize the config when the user is loaded
    if (!context.config && user?.configs?.length) {
      const configs = user?.configs;
      context.setConfig(
        configs.find((c: { slug: null | string }) => {
          return c.slug === configSlug;
        }) ?? configs[0],
      );
    }
  }, [context, user]);

  return context;
}
