import { GraphQLClient } from "graphql-request";

export function getClientOptions() {
  // Copy csrftoken from cookie to header for CSRF protection required by backend.
  const csrftoken =
    (typeof document !== "undefined" ? document : {})?.cookie
      ?.split("; ")
      .find((row) => {
        return row.startsWith("csrftoken=");
      })
      ?.split("=")[1] ?? "";
  return { headers: { "X-Csrftoken": csrftoken } };
}

const client = new GraphQLClient("/graphql/", getClientOptions());
export default client;
