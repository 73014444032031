import { useEffect, useState } from "react";
import { SWRConfig } from "swr";

import { ConfigContext } from "@/hooks/useConfigContext";
import client from "@/lib/client";
import { PERSIST_KEY } from "@/lib/constants";
import { inter } from "@/lib/fonts";

import "@/lib/tailwind.css";

import type { RequestDocument, Variables } from "graphql-request";
import type { AppProps } from "next/app";

import type { Config } from "@/hooks/useConfigContext";

const fetcher = (
  params: [RequestDocument, Variables, AbortController] | RequestDocument,
) => {
  const [document, variables, abortController] = Array.isArray(params)
    ? params
    : [params];
  return client.request<unknown>({
    document: document,
    signal: abortController?.signal,
    variables,
  });
};

const HTTPStatusUnauthorized = 401;

function onError(error: { response?: { status?: number } }) {
  // alert(error.response?.status);
  if (
    error.response?.status === HTTPStatusUnauthorized ||
    error.response?.status === 403
  ) {
    window.location.href = "/login";
  }
}

export default function App({ Component, pageProps }: AppProps) {
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const cleanup = () => {
        localStorage.removeItem(PERSIST_KEY);
      };

      window.addEventListener("beforeunload", cleanup);
      return () => {
        window.removeEventListener("beforeunload", cleanup);
      };
    }
  }, []);

  return (
    <div className={inter.className}>
      <SWRConfig value={{ fetcher, onError }}>
        <ConfigContext.Provider value={{ config, setConfig }}>
          <Component {...pageProps} />
        </ConfigContext.Provider>
      </SWRConfig>
    </div>
  );
}
